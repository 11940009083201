<template>
  <div>
    <c-select
      v-if="isMobile"
      :id="seleniumIds.SELENIUM_TEST_AD_FORM_TYPE"
      :value="adTypeIndex"
      :items="adTypeLabels"
      item-value="value"
      item-text="text"
      :label="title"
      :placeholder="title"
      :disabled="disabled"
      label-bold
      hide-details
      :error-messages="errors.type"
      background-color="white"
      @change="changeAdType($event)"
    />

    <c-tabs-toggle-buttons
      v-else
      :selenium-id="seleniumIds.SELENIUM_TEST_AD_FORM_TYPE"
      :title="title"
      :value="adTypeIndex"
      :buttons="adTypeLabels"
      :error-messages="errors.type"
      :disabled="disabled"
      @input="changeAdType($event)"
    />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import CTabsToggleButtons from '@clickadilla/components/ui/CTabsToggleButtons.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'AdTypeField',
    components: { CSelect, CTabsToggleButtons },
    props: {
      adTypes: {
        type: Array,
        required: true
      },
      title: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['type', 'errors']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      adTypeLabels() {
        return this.adTypes.map((type, index) => ({
          text: this.$t(`main.rotation_types.${type}`),
          value: index
        }))
      },
      adTypeIndex() {
        return this.adTypes.indexOf(this.type)
      }
    },
    created() {
      if (!this.type) {
        this.setType(this.adTypes[0])
      }
    },
    methods: {
      ...mapActions('adForm', ['setType', 'removeError']),
      changeAdType(indexType) {
        this.setType(this.adTypes[indexType])
        this.removeError('type')
      }
    }
  }
</script>
